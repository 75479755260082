import {LoggedInDetails} from "./LoggedInDetails";
import {AgentLicense} from "./AgentLicense";
import {AgentPastSellingCapability} from "./AgentPastSellingCapability";
import {MediaWithType} from "./MediaWithType";
import {AgentIntroductionDetails} from "./AgentIntroductionDetails";
import {AgentTestimonial} from "./AgentTestimonial";
import {WeekDaysEnum} from "../enum/WeekDaysEnum";
import {AgentNotifications} from "./AgentNotifications";
import {AgentCommission} from "./AgentComission";
import {ClientPreferences} from "./ClientPreferences";
import {AgentServiceEnum} from "./AgentServiceEnum";
import {AgentIntroductionType} from "./AgentIntroductionType";
import {AgentPermissions} from "./AgentPermissionsEnum";

export enum AgentType {
  AGENT = "AGENT",
  ADMIN = "ADMIN"
}

export enum AccessRoleType {
  OWNER = "OWNER",
  ADMIN = "ADMIN",
  AGENT = "AGENT",
  CUSTOM = "CUSTOM"
}

export type AgencyAccessRolesTypes = Exclude<AccessRoleType, AccessRoleType.CUSTOM>;

export type AgencyRoleWisePermissions = Record<Exclude<AccessRoleType, AccessRoleType.CUSTOM>, AgentPermissions[]>;

export type TeamMemberBasicDetails = Pick<
  Agent,
  "_id" | "firstName" | "lastName" | "email" | "phone" | "accessRole" | "photo" | "onboardingStep" | "lastActivityAt"
>;

export type NotesMentionableAgent = Pick<
  Agent,
  "_id" | "firstName" | "lastName" | "email" | "phone" | "photo" | "accessRole" | "customPermissions"
> & {
  notesMentionable?: boolean;
  isOwner: boolean;
};
export const AccessRoleTypeLabel: Record<AccessRoleType, string> = {
  [AccessRoleType.OWNER]: "Owner",
  [AccessRoleType.ADMIN]: "Admin",
  [AccessRoleType.AGENT]: "Agent",
  [AccessRoleType.CUSTOM]: "Custom"
};

export enum AgentAccessFeatureName {
  LEAD_POND = "LEAD_POND"
}

export interface AgentAccessFeature {
  name: AgentAccessFeatureName;
}

export enum AgentOnboardingStep {
  INVITED = "INVITED",
  ACCOUNT_ONBOARDING_DETAILS = "ACCOUNT_ONBOARDING_DETAILS",
  PERSONAL_DETAILS = "PERSONAL_DETAILS",
  COMPLETED = "COMPLETED"
}

export interface StartEndTime {
  startTime?: string;
  endTime?: string;
  isEnabled: boolean;
}

export interface AgentOfficePhone {
  ext?: number;
  phone: string;
}

export enum AgentCreationOrigin {
  F_OFF_SELF = "F_OFF_SELF",
  FC_INVITED = "FC_INVITED",
  FC_SELF = "FC_SELF",
  SELLER_INVITED = "SELLER_INVITED",
  TURBO_INVITED = "TURBO_INVITED"
}

export interface ReferralAgreement {
  id: string;
  dateCompleted: Date;
  contentUUID: string;
}

export interface AgentVerification {
  emailVerified: boolean;
  verifiedPhoneNumber?: string;
}

export interface AgentChecklistStatus {
  completedAt: Date;
}

export interface AgentChecklist {
  contactUploaded?: AgentChecklistStatus;
  ioRequested?: AgentChecklistStatus;
  profileCompleted?: AgentChecklistStatus;
  referralAgreementSigned?: AgentChecklistStatus;
  emailVerified?: AgentChecklistStatus;
  introVideoWatched?: AgentChecklistStatus;
  engageSetup?: AgentChecklistStatus;
  signUpCompleted?: AgentChecklistStatus;
}

export type AgentChecklistItem = keyof AgentChecklist;

export interface AgentSignatureSettings {
  showTeamName: boolean;
  showBrokerageName: boolean;
  showLicense: boolean;
}

export interface AgentCampaignMeta {
  paused?: boolean;
}

export interface Agent {
  _id: string;
  email: string;
  accessRole: AccessRoleType;
  customPermissions?: AgentPermissions[];
  agencyId: string;
  onboardingStep: AgentOnboardingStep;
  referralAgreement?: ReferralAgreement;
  lastLoggedIn?: LoggedInDetails;
  lastActivityAt?: string;
  passwordHash?: string;
  firstName: string;
  lastName: string;
  phone?: string;
  officePhone?: AgentOfficePhone;
  photo?: MediaWithType;
  localTimeZone?: string;
  license?: AgentLicense;
  customSignatureText?: string;
  certificates?: string;
  pastSellingCapability?: AgentPastSellingCapability;
  testimonials: AgentTestimonial[];
  commissions?: AgentCommission[];
  clientPreferences?: ClientPreferences;
  services?: AgentServiceEnum[];
  maxOpenHouseForProperty?: number;
  minContractLength?: number;
  workdays?: {[key in WeekDaysEnum]: StartEndTime};
  about?: AgentIntroductionDetails;
  notificationSubscriptions?: AgentNotifications;
  brokerage?: string;
  verifications?: AgentVerification;
  updatedAt?: Date;
  createdAt?: Date;
  origin: AgentCreationOrigin;
  onboardingChecklist?: AgentChecklist;
  introChecklist?: Record<AgentIntroductionType, {completedAt: string}>;
  archived?: boolean;
  signatureSettings?: AgentSignatureSettings;
  totalContactsCount?: number;
  campaignInfo?: AgentCampaignMeta;
  affiliationProgram?: {
    firstPromoterAuthToken: string;
  };
  purl?: {
    id: string;
    landingPageConfigId: string;
  };
  accessFeatures?: AgentAccessFeature[];
  totalMarketingContactsCount: number;
  modifiedBy?: string;
  modifiedOn?: string;
}
