import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {
  AgencyQuoteCheckoutRequest,
  QuoteBillingAddress,
  QuoteCheckoutEstimateRequest,
  QuoteCheckoutEstimateResponse,
  QuoteCheckoutRequest,
  QuoteCheckoutResponse,
  QuoteCheckoutResponseV2,
  QuoteDetailResponse
} from "fello-model";
import {Observable} from "rxjs";
import {PAYMENT_API_SERVICE_BASE_URL} from "../payment";

@Injectable({
  providedIn: "root"
})
export class PublicQuoteApiService {
  baseUrl: string;
  constructor(private http: HttpClient, @Inject(PAYMENT_API_SERVICE_BASE_URL) private paymentBaseUrl: string) {
    this.baseUrl = `${this.paymentBaseUrl}/payment/public/quote`;
  }
  getQuoteDetails(quoteId: string, trackView: boolean): Observable<QuoteDetailResponse> {
    return this.http.get<QuoteDetailResponse>(`${this.baseUrl}/${quoteId}`, {
      params: {
        trackView
      }
    });
  }

  checkoutQuote(quoteId: string, checkoutRequest: QuoteCheckoutRequest): Observable<QuoteCheckoutResponse> {
    return this.http.post<QuoteCheckoutResponse>(`${this.baseUrl}/${quoteId}/checkout`, checkoutRequest);
  }

  quickCheckoutQuote(quoteId: string, checkoutRequest: AgencyQuoteCheckoutRequest): Observable<QuoteCheckoutResponseV2> {
    return this.http.post<QuoteCheckoutResponseV2>(`${this.baseUrl}/${quoteId}/quick-checkout`, checkoutRequest);
  }

  getTaxEstimate(quoteId: string, checkoutEstimateRequest: QuoteCheckoutEstimateRequest): Observable<QuoteCheckoutEstimateResponse> {
    return this.http.post<QuoteCheckoutEstimateResponse>(`${this.baseUrl}/${quoteId}/agency/tax`, {checkoutEstimateRequest});
  }
  //agency/checkout/click
  markCheckoutClick(quoteId: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/${quoteId}/agency/checkout/click`, {});
  }
}
