import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {AgentContactDashboardApiService} from "../../lib/services/contact-dashboard-api/agent-contact-dashboard-api.service";
import {PropertyPublicDashboardApiService} from "../../lib/services/property-public-dashboard-api/property-public-dashboard-api.service";
import {
  AgencyLender,
  AgentCashOfferWrapper,
  AgentContactProperty,
  AgentSignature,
  AVMMultiHistoryInfo,
  ChangelogEventType,
  ClaimStatus,
  CMAFromDashboardRequest,
  ContactAgentFromDashboardRequest,
  DashboardHomeFactsUpdateRequest,
  DashboardModuleButtonAction,
  DashboardModuleButtonActionType,
  FelloConnectedAppType,
  HomeValueFromDashboardRequest,
  LeadRequestFromDashboard,
  PropertyAndContact,
  PropertyHomeEquityResponse,
  TrackTPModuleClickRequest
} from "fello-model";
import {BehaviorSubject, combineLatest, NEVER, Observable, of, ReplaySubject, throwError} from "rxjs";
import {catchError, delay, map, retryWhen, shareReplay, switchMap, take, takeUntil, tap} from "rxjs/operators";
import {DASHBOARD_VIEW_TYPE, DashboardViewType} from "./tokens";
import {mixinDestroyable, StringUtils} from "../../lib";
import {GlobalTrackingEventService, TrackedEvents} from "../event-tracking";
import {HttpErrorResponse, HttpStatusCode} from "@angular/common/http";
import {orderBy} from "lodash-es";
import {AbstractDashboardService} from "./services/abstract-dashboard.service";
import {DashboardSettingsService} from "./services";
import {StateTransferHelperService} from "../fello-ui-lib/services/state-transfer-helper.service";
import {ConfirmationDialogComponent} from "../fello-ui-lib/components/confirmation-dialog/confirmation-dialog.component";
import {isPlatformBrowser} from "@angular/common";
import {AddAddressComponent, AddAddressComponentData} from "./components/manage-address/add-address/add-address.component";
import {MatDialog} from "@angular/material/dialog";
import {NgxSpinnerService} from "ngx-spinner";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SocialSettingsService} from "../fello-ui-lib";

@Injectable()
export class DashboardService extends mixinDestroyable(AbstractDashboardService) {
  protected propertyIdSubject = new ReplaySubject<string>(1);
  private propertyId$ = this.propertyIdSubject.asObservable();
  private isPropertyLoadingSubject = new BehaviorSubject(true);
  isPropertyLoading = this.isPropertyLoadingSubject.asObservable();
  refreshPropertySubject = new BehaviorSubject<void>(undefined);
  isDummyDashboard$ = of(false);

  private isOfferLoadingSubject = new BehaviorSubject(true);

  propertyAndContact$ = combineLatest([this.propertyId$, this.refreshPropertySubject]).pipe(
    switchMap(([propertyId]) => {
      this.isPropertyLoadingSubject.next(true);
      return this.stateTransferHelperService
        .transferOnce(this.propertyPublicDashboardApiService.getPropertyAndContact(propertyId), `prop:${propertyId}`)
        .pipe(
          switchMap(propertyAndContact => {
            return of(propertyAndContact);
          }),
          catchError(err => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === HttpStatusCode.NotFound) {
                this.router.navigate(["/not-found"], {
                  skipLocationChange: true
                });
                return NEVER;
              }
            }
            return throwError(err);
          })
        );
    }),
    tap(
      propertyAndContact => {
        this.isPropertyLoadingSubject.next(false);
        this.trackDashboardViewedEvent(propertyAndContact);
      },
      () => this.isPropertyLoadingSubject.next(false)
    ),
    retryWhen(err => err.pipe(delay(5000))),
    shareReplay(1)
  );

  property$ = this.propertyAndContact$.pipe(map(propertyAndContact => propertyAndContact.property));
  contact$ = this.propertyAndContact$.pipe(map(propertyAndContact => propertyAndContact.contact));

  offer$: Observable<AgentCashOfferWrapper> = this.property$.pipe(
    takeUntil(this.isDestroyed),
    switchMap(property => {
      this.isOfferLoadingSubject.next(true);
      return this.propertyPublicDashboardApiService.getAgentCashOfferWrapper(property.propertyId).pipe(
        map(offerWrapper => {
          offerWrapper.offers = orderBy(offerWrapper.offers, ["createdAt"], ["desc"]);
          return offerWrapper;
        })
      );
    }),
    tap(() => {
      this.isOfferLoadingSubject.next(false);
    })
  );

  agentSignature$: Observable<AgentSignature | null> = this.property$.pipe(
    takeUntil(this.isDestroyed),
    switchMap(property => {
      return this.propertyPublicDashboardApiService.getSignature(property.propertyId).pipe(retryWhen(err => err.pipe(delay(5000))));
    }),
    shareReplay(1)
  );

  lender$: Observable<AgencyLender | undefined> = this.dashboardSettingsService.settings$.pipe(
    takeUntil(this.isDestroyed),
    switchMap(settings => of(settings?.lender)),
    shareReplay(1)
  );
  homeEstimate$ = this.property$.pipe(
    takeUntil(this.isDestroyed),
    switchMap(property => {
      if (isPlatformBrowser(this.platformId)) {
        return this.propertyPublicDashboardApiService.getHomeEstimate(property.propertyId).pipe(retryWhen(err => err.pipe(delay(5000))));
      }
      return NEVER;
    }),
    shareReplay(1)
  );
  felloMultiAvmResponse$ = this.property$.pipe(
    takeUntil(this.isDestroyed),
    switchMap(property => {
      if (isPlatformBrowser(this.platformId)) {
        return this.propertyPublicDashboardApiService
          .getAllHomeEstimates(property.propertyId)
          .pipe(retryWhen(err => err.pipe(delay(5000))));
      }
      return NEVER;
    }),
    shareReplay(1)
  );

  sellingOptionsResponse$ = this.property$.pipe(
    takeUntil(this.isDestroyed),
    switchMap(property => {
      if (isPlatformBrowser(this.platformId)) {
        return this.propertyPublicDashboardApiService.getSellingOptions(property.propertyId).pipe(retryWhen(err => err.pipe(delay(5000))));
      }
      return NEVER;
    }),
    shareReplay(1)
  );

  comps$ = this.property$.pipe(
    takeUntil(this.isDestroyed),
    switchMap(property => {
      if (isPlatformBrowser(this.platformId)) {
        return this.propertyPublicDashboardApiService.getCompsWithEstimate(property.propertyId).pipe(
          retryWhen(err => err.pipe(delay(5000))),
          map(compWithAVM => compWithAVM.comps)
        );
      }
      return NEVER;
    }),
    shareReplay(1)
  );

  multiAvmHistory$: Observable<AVMMultiHistoryInfo> = this.property$.pipe(
    takeUntil(this.isDestroyed),
    switchMap(property => {
      return this.homeEstimate$.pipe(
        switchMap(hvEstimate => {
          if (!hvEstimate.median) {
            return of({
              history: []
            } as AVMMultiHistoryInfo);
          }
          return this.propertyPublicDashboardApiService
            .getMultiAvmHistoryDetail(property.propertyId)
            .pipe(retryWhen(err => err.pipe(delay(5000))));
        })
      );
    }),
    shareReplay(1)
  );

  homeEquity$: Observable<PropertyHomeEquityResponse> = this.property$.pipe(
    takeUntil(this.isDestroyed),
    switchMap(property => {
      return this.propertyPublicDashboardApiService.getEquityDetails(property.propertyId).pipe(retryWhen(err => err.pipe(delay(5000))));
    }),
    shareReplay(1)
  );

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    @Inject(DASHBOARD_VIEW_TYPE) public dashboardViewType: DashboardViewType,
    private globalTrackingEventService: GlobalTrackingEventService,
    private propertyPublicDashboardApiService: PropertyPublicDashboardApiService,
    private agentContactDashboardApiService: AgentContactDashboardApiService,
    private dashboardSettingsService: DashboardSettingsService,
    private stateTransferHelperService: StateTransferHelperService,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private platformId: Object,
    private dialog: MatDialog,
    private spinnerService: NgxSpinnerService,
    private snackBar: MatSnackBar,
    private socialSettingsService?: SocialSettingsService
  ) {
    super();
    this.initPropertyId();
    combineLatest([this.property$, this.dashboardSettingsService.settings$])
      .pipe(takeUntil(this.isDestroyed))
      .subscribe(([property, settings]) => {
        this.socialSettingsService?.applySocialSettings({
          title: `${property.addressComponents.fullAddress} | ${settings?.name ?? ""}`,
          description: "Personalized dashboard with tailored content for real time insights",
          image: "https://hifello.com/hubfs/connect/no_property_image.jpg"
        });
      });
  }

  protected trackDashboardViewedEvent(propertyAndContact: PropertyAndContact): void {
    this.globalTrackingEventService.trackCustomEvent(TrackedEvents.VIEW_DASHBOARD);
  }

  protected initPropertyId(): void {
    this.route.paramMap
      .pipe(
        map(params => params.get("propertyId")),
        takeUntil(this.isDestroyed)
      )
      .subscribe(propertyId => {
        if (propertyId) {
          this.updatePropertyId(propertyId);
        }
      });
  }

  protected updatePropertyId(propertyId: string): void {
    this.propertyIdSubject.next(propertyId);
  }

  refreshProperty(): void {
    this.refreshPropertySubject.next();
  }

  updateBuyingWithSelling(buyingArea: string, buyingPriceLow: number, buyingPriceHigh: number): Observable<void> {
    return this.propertyId$.pipe(
      take(1),
      switchMap(propertyId =>
        this.propertyPublicDashboardApiService.updateBuyingWithSelling(propertyId, buyingArea, buyingPriceLow, buyingPriceHigh)
      ),
      tap(() => this.refreshProperty())
    );
  }

  updateHomeFacts(facts: DashboardHomeFactsUpdateRequest): Observable<void> {
    return this.propertyId$.pipe(
      take(1),
      switchMap(propertyId => this.propertyPublicDashboardApiService.updateHomeFacts(propertyId, facts)),
      tap(() => this.refreshProperty())
    );
  }

  trackEventAsync(eventType: ChangelogEventType, eventData?: any): void {
    if (this.dashboardViewType === DashboardViewType.HOME_OWNER) {
      this.propertyId$
        .pipe(
          take(1),
          switchMap(propertyId => {
            return this.propertyPublicDashboardApiService.trackDashboardEvent(propertyId, eventType, eventData);
          })
        )
        .subscribe();
    }
  }

  submitContactAgentForm(
    eventType: ChangelogEventType,
    contactAgentFromDashboardRequest: ContactAgentFromDashboardRequest
  ): Observable<void> {
    if (this.dashboardViewType === DashboardViewType.HOME_OWNER) {
      return this.propertyId$.pipe(
        take(1),
        switchMap(propertyId => {
          return this.propertyPublicDashboardApiService.submitContactAgentForm(propertyId, eventType, contactAgentFromDashboardRequest);
        })
      );
    }
    return of(undefined);
  }

  submitRequestCMAForm(cmaRequest: CMAFromDashboardRequest): Observable<void> {
    return this.propertyId$.pipe(
      take(1),
      switchMap(propertyId => this.propertyPublicDashboardApiService.submitRequestCMAForm(propertyId, cmaRequest)),
      tap(() => this.refreshProperty())
    );
  }

  performCTAAction(action: DashboardModuleButtonAction, target?: string): Observable<{url: string}> {
    return this.propertyId$.pipe(
      take(1),
      switchMap(propertyId => this.propertyPublicDashboardApiService.resolveCTAAction(propertyId, action)),
      tap(({url}) => {
        target = target ?? action.actionType === DashboardModuleButtonActionType.URL ? "_blank" : "_self";
        window.open(url, target);
      })
    );
  }

  unClaimClaimedProperty(property: AgentContactProperty): Observable<boolean> {
    return this.contact$.pipe(map(contact => contact.properties)).pipe(
      take(1),
      switchMap(properties => {
        if (properties.filter(property => property.claimStatus === ClaimStatus.CLAIMED).length > 1) {
          return this.unClaimUnClaimedProperty(property);
        }
        return this.unClaimClaimedPropertyAndAddNewProperty(property);
      })
    );
  }

  private unClaimClaimedPropertyAndAddNewProperty(property: AgentContactProperty): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: "Are you Sure?",
        description: `You will lose all access to details for ${property.addressComponents.doorNumber} ${property.addressComponents.street}.`,
        okButtonText: "Not my home",
        cancelButtonText: "Cancel",
        okButtonClasses: ["button-red", "border-none"]
      },
      autoFocus: false,
      panelClass: ["mobile-bottom-sheet"]
    });
    return dialogRef.afterClosed().pipe(
      switchMap(confirmUnclaim => {
        if (!confirmUnclaim) {
          return of(confirmUnclaim);
        }
        return this.addNewAddress((propertyId: string, rawAddress: string, hvRequestFromDashboard: HomeValueFromDashboardRequest) =>
          this.agentContactDashboardApiService.unClaimAddressAndAddAddressToDashboard(propertyId, rawAddress, hvRequestFromDashboard)
        );
      })
    );
  }

  unClaimUnClaimedProperty(property: AgentContactProperty): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: "Are you Sure?",
        description: `You will lose all access to details for ${property.addressComponents.doorNumber} ${property.addressComponents.street}.`,
        okButtonText: "Not my home",
        cancelButtonText: "Cancel",
        okButtonClasses: ["button-red", "border-none"]
      },
      autoFocus: false,
      panelClass: ["mobile-bottom-sheet"]
    });
    return dialogRef.afterClosed().pipe(
      switchMap(confirmUnclaim => {
        if (!confirmUnclaim) {
          return of(false);
        }
        this.spinnerService.show();
        return this.agentContactDashboardApiService.unClaimAddressFromDashboard(property.propertyId).pipe(
          tap(
            () => {
              this.spinnerService.hide();
            },
            () => {
              this.spinnerService.hide();
              this.snackBar.open("Unable to un-claim. Please try again later", undefined, {
                duration: 4000
              });
            }
          ),
          switchMap(() => {
            return combineLatest([this.property$, this.contact$.pipe(map(contact => contact.properties))]).pipe(
              take(1),
              tap(([currentProperty, propertyList]) => {
                if (currentProperty.propertyId === property.propertyId) {
                  const nextClaimedProperty = propertyList.find(
                    p => p.propertyId !== currentProperty.propertyId && p.claimStatus === ClaimStatus.CLAIMED
                  );
                  if (nextClaimedProperty) {
                    this.router.navigate(["/dashboard", nextClaimedProperty.propertyId]);
                  }
                } else {
                  this.refreshProperty();
                }
              }),
              map(() => true)
            );
          })
        );
      })
    );
  }

  addNewAddress(
    addAddressAction?: (
      propertyId: string,
      rawAddress: string,
      hvRequestFromDashboard: HomeValueFromDashboardRequest
    ) => Observable<AgentContactProperty>
  ): Observable<boolean> {
    return combineLatest([this.property$, this.contact$]).pipe(
      take(1),
      switchMap(([property, contact]) => {
        const {last, first} = StringUtils.splitFullName(contact.fullName ?? "");
        return this.dialog
          .open(AddAddressComponent, {
            data: <AddAddressComponentData>{
              propertyId: property.propertyId,
              emailId: contact.emailId,
              hvRequestFromDashboard: {
                firstName: first,
                lastName: last,
                phone: contact.phone,
                emailId: contact.emailId
              },
              addAddressAction
            },
            panelClass: ["mobile-bottom-sheet-2"],
            restoreFocus: false
          })
          .afterClosed()
          .pipe(
            map(isAddressAdded => {
              return !!isAddressAdded;
            })
          );
      })
    );
  }

  trackTPModuleClick(trackRequest: TrackTPModuleClickRequest): Observable<{_id: string}> {
    return this.propertyId$.pipe(
      take(1),
      switchMap(propertyId => this.propertyPublicDashboardApiService.trackTPModuleClick(propertyId, trackRequest))
    );
  }
  submitCTAPopupLeadForm(
    action: DashboardModuleButtonAction,
    data: LeadRequestFromDashboard,
    connectedAppType?: FelloConnectedAppType
  ): Observable<void> {
    return this.propertyId$.pipe(
      take(1),
      switchMap(propertyId => this.propertyPublicDashboardApiService.submitCTAPopupLeadForm(propertyId, action, data, connectedAppType)),
      tap(() => this.refreshProperty())
    );
  }
}
