import {MediaWithType} from "./MediaWithType";
import {AgencyPlan, FeatureName} from "./feature";
import {WidgetSettings} from "./WidgetSettings";
import {TimeDuration} from "../TimeDuration";
import {DNSRecordEntry} from "../common";
import {AddressComponent, AddressData} from "../address";

export interface AgencyWidgetInfo {
  widgetId: string;
  settings: WidgetSettings;
}

export interface ReferralSettings {
  defaultOwnerId: string;
}
export enum RealSellerNotificationType {
  REAL_TIME = "REAL_TIME", //Real time for all real seller events
  SUMMARY = "SUMMARY", //Single report per given time period, eg daily
  DISABLED = "DISABLED" //Off
}
export interface AgencyNotificationSettings {
  receiverEmails?: string[];
  realSellerEventReceiverEmails?: string[];
  realSellerNotificationType: RealSellerNotificationType;
}

export interface LeadAssignmentSettings {
  defaultAssignee: string | null;
}
export interface AgencyCRMSettings {
  skipOwnerUpdateForInboundEvents: boolean;
}

export interface NonMarketingContactConfig {
  markNonMarketingOnInvalidEmail: boolean;
}
export interface AgencyDefaultSettings {
  autoOfferAggRequest?: boolean;
  notification?: AgencyNotificationSettings;
  subdomain?: string;
  defaultDashboardId?: string;

  nonPlanFeatures?: FeatureName[];
  leadAssignment?: LeadAssignmentSettings;
  crmSettings?: AgencyCRMSettings;
  homeEstimateOverridePerc?: number;
  homeEstimateAvmChangePercCutOff?: number;
  nonMarketingContactConfig?: NonMarketingContactConfig;
  softFreezeAfter?: string;
}

export enum AgencyEngageRunLevel {
  AGENT = "AGENT",
  ACCOUNT = "ACCOUNT"
}

export interface AgencyCampaignInfo {
  paused?: boolean;
  runLevel?: AgencyEngageRunLevel;
  disableWelcomeCampaign?: boolean;
  firstMailStartAfterEnrollmentDuration?: TimeDuration;
  stopped?: boolean;
}

export interface SendingDomainDNSSettings {
  dkimConfig: DNSRecordEntry;
  bounceConfig: DNSRecordEntry;
  trackingDomainConfig: DNSRecordEntry[];
  dmarcConfig: DNSRecordEntry;
}

export interface AgencyOverriddenCustomDomainSettings {
  referenceAgencyId: string;
}

export interface AgencyCustomDomainSettings {
  domain: string;
  linkTrackingDomain: string;
  bounceDomain: string;
  enabled?: boolean;
  sendingEmail?: string;
  dnsSettings?: SendingDomainDNSSettings;
  domainAddedDate: Date;
  domainVerifiedDate?: Date;
  sendingEmailAddedDate?: Date;
}
export enum LenderType {
  INDIVIDUAL = "INDIVIDUAL",
  COMPANY = "COMPANY"
}
export enum AgencyStatus {
  ACTIVE = "ACTIVE",
  CREATED = "CREATED",
  CHURNED = "CHURNED"
}
export interface AgencyLender {
  lenderType: LenderType;
  logoMedia?: MediaWithType;
  name?: string;
  email: string;
  phone?: string;
  companyName?: string;
  nmlsId: string;
}

export interface AgencyChargeBeeRef {
  subscriptionId: string;
  customerId: string;
}

export interface EnrichmentConfig {
  isEnabled: boolean;
  recordsLimit: number;
}

export interface Agency {
  _id: string;
  email?: string;
  name: string;
  brokerage?: string;
  noBrokerage?: boolean;
  introduction?: string;
  address?: AddressData;
  addressComponent?: AddressComponent;
  widget?: AgencyWidgetInfo;
  plan: AgencyPlan;
  updatedAt?: Date;
  createdAt?: Date;
  referralSettings?: ReferralSettings;
  defaultSettings?: AgencyDefaultSettings;
  totalAgentsCount: number;
  totalContactsCount: number;
  campaignInfo?: AgencyCampaignInfo;
  customDomainSettings?: AgencyCustomDomainSettings;
  overriddenCustomDomainSettings?: AgencyOverriddenCustomDomainSettings;
  lender?: AgencyLender;
  accountOwnerId?: string;
  creditBalance: number;
  chargeBeeRef?: AgencyChargeBeeRef;
  totalMarketingContactsCount: number;
  enrichmentConfig?: EnrichmentConfig;
  status: AgencyStatus;
}
