import {ContactAgentDashboardModuleSettings} from "./modules/settings/ContactAgentDashboardModuleSettings";
import {PrimaryCTADashboardModuleSettings} from "./modules/settings/PrimaryCTADashboardModuleSettings";
import {BaseDashboardModuleSettings} from "./modules/BaseDashboardModuleSettings";
import {VideoDashboardModuleSettings} from "./modules/settings/VideoDashboardModuleSettings";
import {HTMLDashboardModuleSettings} from "./modules/settings/HTMLDashboardModuleSettings";
import {
  CompsDashboardModuleSettings,
  CTADashboardModuleSettings,
  HomeEstimateDashboardModuleSettings,
  HomeValueTrendDashboardModuleSettings,
  LenderDashboardModuleSettings,
  ThreeStepProcessDashboardModuleSettings
} from "./modules";
import {YourHomeEquityDashboardModuleSettings} from "./modules/settings/YourHomeEquityDashboardModuleSettings";
import {FQLOrExpr} from "../mvc";
import {HomeDetailsDashboardModuleSettings} from "./modules/settings/HomeDetailsDashboardModuleSettings";
import {MultiAVMDashboardModuleSettings} from "./modules/settings/MultiAVMDashboardModuleSettings";
import {SellingOptionsDashboardModuleSettings} from "./modules/settings/SellingOptionsDashboardModuleSettings";

export enum DashboardModuleType {
  CONTACT_AGENT = "CONTACT_AGENT",
  PRIMARY_CTA = "PRIMARY_CTA",
  VIDEO = "VIDEO",
  CTA = "CTA",
  HTML = "HTML",
  HOME_ESTIMATE = "HOME_ESTIMATE",
  HOME_VALUE_TREND = "HOME_VALUE_TREND",
  COMPS = "COMPS",
  HOME_DETAILS = "HOME_DETAILS",
  BUYING_PREF = "BUYING_PREF",
  LENDER = "LENDER",
  THREE_STEP_PROCESS = "THREE_STEP_PROCESS",
  HOME_EQUITY = "HOME_EQUITY",
  MULTI_AVM = "MULTI_AVM",
  SELLING_OPTIONS = "SELLING_OPTIONS"
}

export const DashboardModuleTypeLabel: Record<DashboardModuleType, string> = {
  [DashboardModuleType.CONTACT_AGENT]: "Contact Agent",
  [DashboardModuleType.PRIMARY_CTA]: "Primary CTA",
  [DashboardModuleType.VIDEO]: "Video Module",
  [DashboardModuleType.CTA]: "Call-to-action Module",
  [DashboardModuleType.HTML]: "Custom HTML Module",
  [DashboardModuleType.LENDER]: "Lender Module",
  [DashboardModuleType.THREE_STEP_PROCESS]: "3 Step Process",

  [DashboardModuleType.HOME_ESTIMATE]: "Home Estimate",
  [DashboardModuleType.HOME_VALUE_TREND]: "Home Value Trend",
  [DashboardModuleType.COMPS]: "Recent Home Sales",
  [DashboardModuleType.HOME_DETAILS]: "Home Details",
  [DashboardModuleType.BUYING_PREF]: "Buying a Home",
  [DashboardModuleType.HOME_EQUITY]: "Home Equity Calculator",
  [DashboardModuleType.MULTI_AVM]: "Multi-AVM Module",
  [DashboardModuleType.SELLING_OPTIONS]: "Multi Offer Module"
};

interface ModuleTypeToSettingsMap {
  [DashboardModuleType.CONTACT_AGENT]: ContactAgentDashboardModuleSettings;
  [DashboardModuleType.PRIMARY_CTA]: PrimaryCTADashboardModuleSettings;
  [DashboardModuleType.VIDEO]: VideoDashboardModuleSettings;
  [DashboardModuleType.CTA]: CTADashboardModuleSettings;
  [DashboardModuleType.HTML]: HTMLDashboardModuleSettings;
  [DashboardModuleType.LENDER]: LenderDashboardModuleSettings;
  [DashboardModuleType.THREE_STEP_PROCESS]: ThreeStepProcessDashboardModuleSettings;

  [DashboardModuleType.HOME_ESTIMATE]: HomeEstimateDashboardModuleSettings;
  [DashboardModuleType.HOME_VALUE_TREND]: HomeValueTrendDashboardModuleSettings;
  [DashboardModuleType.COMPS]: CompsDashboardModuleSettings;
  [DashboardModuleType.HOME_DETAILS]: HomeDetailsDashboardModuleSettings;
  [DashboardModuleType.BUYING_PREF]: BaseDashboardModuleSettings;
  [DashboardModuleType.HOME_EQUITY]: YourHomeEquityDashboardModuleSettings;
  [DashboardModuleType.MULTI_AVM]: MultiAVMDashboardModuleSettings;
  [DashboardModuleType.SELLING_OPTIONS]: SellingOptionsDashboardModuleSettings;
}

export type AvailableDashboardModules = keyof ModuleTypeToSettingsMap;
export type DashboardModuleSetting<ModuleType extends AvailableDashboardModules> = ModuleTypeToSettingsMap[ModuleType];

export enum DashboardModuleVisibilityLevel {
  ALL = "ALL",
  FILTERED = "FILTERED"
}

export const DashboardModuleVisibilityLevelLabel: Record<DashboardModuleVisibilityLevel, string> = {
  [DashboardModuleVisibilityLevel.ALL]: "All Contacts",
  [DashboardModuleVisibilityLevel.FILTERED]: "Based on Filters"
};

export interface DashboardModuleVisibilitySettings {
  visibilityLevel: DashboardModuleVisibilityLevel;
  filters?: FQLOrExpr;
}

export interface DashboardModuleConfig<ModuleType extends AvailableDashboardModules = AvailableDashboardModules> {
  moduleId: string;
  moduleType: ModuleType;
  seq: number;
  isActive: boolean;
  settings: DashboardModuleSetting<ModuleType>;
  visibilitySettings?: DashboardModuleVisibilitySettings;
}
