import {FQLOrExpr} from "../../mvc/filtering";
import {ChangelogTriggeredBy} from "../../deal";

export enum ContactSegmentType {
  S = "S",
  U = "U", // TODO: update after going live with Segment watch.
  PRIVATE = "P"
}

export const ContactSegmentTypeLabel: Record<ContactSegmentType, string> = {
  [ContactSegmentType.S]: "Standard",
  [ContactSegmentType.U]: "Shared",
  [ContactSegmentType.PRIVATE]: "Private"
};

export const ContactSegmentTypeDescriptionLabel: Record<ContactSegmentType, string> = {
  [ContactSegmentType.S]: "Shared with all users",
  [ContactSegmentType.U]: "Shared with all users",
  [ContactSegmentType.PRIVATE]: "Only Me"
};

export const ContactSegmentTypeIcon: Record<ContactSegmentType, string> = {
  [ContactSegmentType.S]: "doc-filter",
  [ContactSegmentType.U]: "users-shared",
  [ContactSegmentType.PRIVATE]: "lock-3"
};

export interface ContactSegmentWrapper {
  name: string;
  description?: string;
  type?: ContactSegmentType;
  fqlFilters?: FQLOrExpr;
  /**
   * @deprecated order is to be removed, AgentSegmentPreference will be used.
   */
  order: number;
}

export interface AgencyContactSegmentUpdateRequest {
  name: string;
  description?: string;
  type: ContactSegmentType;
  fqlFilters?: FQLOrExpr;
  /**
   * @deprecated order is to be removed, AgentSegmentPreference will be used.
   */
  order?: number;
  featured?: boolean;
}

export type AgencyContactSegmentCreateRequest = AgencyContactSegmentUpdateRequest;

export interface AgencyContactSegment {
  _id: string;
  name: string;
  agencyId: string;
  description?: string;
  type: ContactSegmentType;
  fqlFilters?: FQLOrExpr;
  internalSegmentKey: string;
  filtersLastUpdatedAt: string;
  featured?: boolean;
  createdBy: ChangelogTriggeredBy;
  updatedBy: ChangelogTriggeredBy;
  createdAt: string;
  updatedAt: string;
}

export interface CatalogSegment extends AgencyContactSegment {
  isPinned: boolean;
  isVisible: boolean;
  isWatched: boolean;
}

export interface WatchedSegment {
  segmentId: string;
  lastWebNotificationSentAt?: string;
  lastEmailNotificationSentAt?: string;
  startedWatchingAt: string;
}

export interface AgentSegmentPreference {
  _id: string; // agentId
  agencyId: string;
  visibleStandardSegmentIds: string[];
  pinnedSegmentIds: string[];
  visiblePrivateSegmentIds: string[];
  visibleSharedSegmentIds: string[];
  watchedSegments: WatchedSegment[];
  segmentsGroupOrder: ContactSegmentType[];
}
