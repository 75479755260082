<div [ngClass]="{'flex-box agent-img-c' : agentSignature.type === AgentSignatureType.AGENT}">
  <ng-container *ngIf="agentSignature.type ===AgentSignatureType.AGENT; else agencyLogo">
    <img
      [src]="agentSignature.photo | mediaWithType:{auto: 'compress', fit: 'clip', fallback:'/assets/images/svg/user.png'}"
      alt="Agent"
      class="agent-logo"
    />
  </ng-container>
  <ng-template #agencyLogo>
    <img
      [src]="agentSignature.photo | mediaWithType:{auto: 'compress', fit: 'clip',
    fallback:'/assets/images/svg/no_property_image_old.svg'}"
      alt="Agency"
      class="agency-logo"
    />
  </ng-template>

  <div class="flex-item-auto-sizing">
    <ng-container *ngFor="let setting of agentSignature.signatureElementsSettings">
      <ng-container *ngIf="templateMap.get(setting.type); let template">
        <ng-container *ngTemplateOutlet="template; context: {data: setting.value}"></ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>

<!-- Display Name Template -->
<ng-template #displayNameTemplate let-data="data">
  <h3 *ngIf="data" class="text-size-13 fw-600 m-0">{{data}}</h3>
</ng-template>

<!-- Team Name Template -->
<ng-template #brandNameTemplate let-data="data">
  <h3 *ngIf="data" class="text-size-13 fw-600 m-0">{{data}}</h3>
</ng-template>

<!-- Brokerage Template -->
<ng-template #brokerageTemplate let-data="data">
  <h3 *ngIf="data" class="text-size-13 fw-600 m-0">{{data}}</h3>
</ng-template>

<!-- License Template -->
<ng-template #licenseTemplate let-data="data">
  <span *ngIf="data?.licenseNumber" class="text-size-12 text-color-light-2 text-overflow-ellipsis d-block">
    License# {{data?.licenseNumber}}
  </span>
</ng-template>

<!-- Custom Signature Field Template -->
<ng-template #additonalSignatureTextTemplate let-data="data">
  <span *ngIf="data" class="text-size-12 text-color-light-2 text-overflow-ellipsis d-block">
    {{data}}
  </span>
</ng-template>

<!-- Email Id Template -->
<ng-template #emailIdTemplate let-data="data">
  <span *ngIf="data" class="text-size-12 text-color-blue text-overflow-ellipsis d-block email"> {{data}} </span>
</ng-template>

<!-- Phone Template -->
<ng-template #phoneTemplate let-data="data">
  <span *ngIf="data" class="text-size-12 text-color-grey-secondary grey mb-0 d-block">{{data}}</span>
</ng-template>

<!-- Office Phone Template -->
<ng-template #officePhoneTemplate let-data="data">
  <span *ngIf="data?.phone" class="text-size-12 text-color-grey-secondary grey mb-0 d-block">
    {{data?.phone}}
    <ng-container *ngIf="data?.ext"> Ext. {{data?.ext}}</ng-container>
  </span>
</ng-template>

<!-- Website Template -->
<ng-template #websiteTemplate let-data="data">
  <span *ngIf="AgentBrandingUtils.getAgencyWebsiteDisplayText(data); let website">
    <a
      [href]="data"
      target="_blank"
      *ngIf="data"
      class="link text-size-12 text-color-blue text-overflow-ellipsis d-block website"
      [ngClass]="{'long-website' : website.length > 30}"
    >
      {{website}}
    </a>
  </span>
</ng-template>

<!-- Address Template -->
<ng-template #addressTemplate let-data="data">
  <h3 *ngIf="data" class="text-size-12 fw-400 m-0">{{data}}</h3>
</ng-template>
