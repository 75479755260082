import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";

import {DashboardWrapperComponent} from "./components/dashboard-wrapper/dashboard-wrapper.component";
import {DashboardHeaderComponent} from "./components/dashboard-header/dashboard-header.component";
import {DashboardFooterComponent} from "./components/dashboard-footer/dashboard-footer.component";
import {HomeDetailsComponent} from "./components/home-details/home-details.component";
import {HomeValueEstimateComponent} from "./components/home-value-estimate/home-value-estimate.component";
import {FelloUiUtilsModule} from "../fello-ui-utils/fello-ui-utils.module";
import {ComparablesComponent} from "./components/comparables/comparables.component";
import {CompCardComponent} from "./components/comparables/comp-card/comp-card.component";
import {MatDialogModule} from "@angular/material/dialog";
import {UserInfoComponent} from "./components/user-info/user-info.component";
import {MatSelectModule} from "@angular/material/select";
import {MatTabsModule} from "@angular/material/tabs";
import {GoogleMapsModule} from "@angular/google-maps";
import {CompLabelComponent} from "./components/comparables/comp-label/comp-label.component";
import {DashboardItemDirective} from "./directives/dashboard-item.directive";
import {BuyingHomeComponent} from "./components/buying-home/buying-home.component";
import {SellAnotherHomeComponent} from "./components/sell-another-home/sell-another-home.component";
import {FaqComponent} from "./components/faq/faq.component";
import {MatTooltipModule} from "@angular/material/tooltip";
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import {FormsModule} from "@angular/forms";
import {SuccessDialogComponent} from "./components/dialogs/success-dialog/success-dialog.component";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {HomeDetailsEditDialogComponent} from "./components/home-details/home-details-edit-dialog/home-details-edit-dialog.component";
import {HomeDetailsStatsComponent} from "./components/home-details/home-details-stats/home-details-stats.component";
import {HomeDetailsAdditionalInfoComponent} from "./components/home-details/home-details-additional-info/home-details-additional-info.component";
import {HomeDetailsConditionComponent} from "./components/home-details/home-details-condition/home-details-condition.component";
import {HomeDetailsConditionDialogComponent} from "./components/home-details/home-details-condition/home-details-condition-dialog/home-details-condition-dialog.component";
import {AddBuyingHomeDialogComponent} from "./components/buying-home/add-buying-home-dialog/add-buying-home-dialog.component";
import {RouterModule} from "@angular/router";
import {
  FullViewGalleryComponent,
  MediaIdsFullViewGalleryComponent
} from "./components/dialogs/full-view-gallery/full-view-gallery.component";
import {NgxGalleryModule} from "@kolkov/ngx-gallery";
import {FaqAgentComponent} from "./components/faq/faq-agent/faq-agent.component";
import {MatMenuModule} from "@angular/material/menu";
import {AgentContactAddAddressComponent} from "./components/add-address/agent-contact-add-address.component";
import {FelloUiLibModule} from "../fello-ui-lib";
import {ManageAddressComponent} from "./components/manage-address/manage-address.component";
import {ManageAddressDialogComponent} from "./components/manage-address/manage-address-dialog/manage-address-dialog.component";
import {CalculateEstimateDialogComponent} from "./components/home-value-estimate/calculate-estimate-dialog/calculate-estimate-dialog.component";
import {NgxChartsModule} from "@swimlane/ngx-charts";
import {HomeValueWithStepsComponent} from "./components/home-value-with-steps/home-value-with-steps.component";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {RealEstateAgentComponent} from "./components/real-estate-agent/real-estate-agent.component";
import {NgxSpinnerModule} from "ngx-spinner";
import {CashOfferComponent} from "./components/cash-offer/cash-offer.component";
import {CashOfferListComponent} from "./components/cash-offer-list/cash-offer-list.component";
import {OffersDashboardWrapperComponent} from "./components/dashboard-wrapper/offers-dashboard-wrapper/offers-dashboard-wrapper.component";
import {OfferDetailsComponent} from "./components/cash-offer-list/offer-details/offer-details.component";
import {AddAddressComponent} from "./components/manage-address/add-address/add-address.component";
import {SignatureModule} from "../signature";
import {PropertyIdToHomeEstimateDirective} from "./directives/property-id-to-home-estimate.directive";
import {ContactAgentComponent} from "./components/dialogs/contact-agent/contact-agent.component";
import {DashboardEasyLinkComponent} from "./components/dashboard-easy-link/dashboard-easy-link.component";
import {ImproveHomeEstimateComponent} from "./components/dialogs/improve-home-estimate/improve-home-estimate.component";
import {DashboardModuleDirective} from "./directives/dashboard-module.directive";
import {DashboardModuleHostDirective} from "./directives/dashboard-module-host.directive";
import {VideoModuleComponent} from "./components/video-module/video-module.component";
import {HtmlModuleComponent} from "./components/html-module/html-module.component";
import {CtaModuleComponent} from "./components/cta-module/cta-module.component";
import {LenderComponent} from "./components/lender/lender.component";
import {ComparablesMapDummyComponent} from "./components/comparables/comparables-map-dummy/comparables-map-dummy.component";
import {ThreeStepProcessComponent} from "./components/three-step-process/three-step-process.component";
import {HomeEquityComponent} from "./components/home-equity/home-equity.component";
import {MatSliderModule} from "@angular/material/slider";
import {ClaimAddressComponent} from "./components/claim-address/claim-address.component";
import {OwnAnotherHomeComponent} from "./components/own-another-home/own-another-home.component";
import {HomeValueTrendV2Component} from "./components/home-value-trend-v2/home-value-trend-v2.component";
import {HomeValueTrendCalculationV2Component} from "./components/dialogs/home-value-trend-calculation-v2/home-value-trend-calculation-v2.component";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MultiAvmComponent} from "./components/multi-avm/multi-avm.component";
import {HomeValueGraphComponent} from "./components/home-value-trend-v2/home-value-graph/home-value-graph.component";
import {DynamicTooltipDirective, PropertyExternalImgPipe} from "../fello-ui-utils";
import {AvmUpdatedByAgentStripComponent, HomeValueRangeGraphComponent, PopupLeadFormComponent} from "./components";
import {OfferAvailabilityComponent} from "./components/offer-availability/offer-availability.component";

const declarations = [
  DashboardWrapperComponent,
  DashboardHeaderComponent,
  DashboardFooterComponent,
  HomeDetailsComponent,
  HomeValueEstimateComponent,
  ComparablesComponent,
  UserInfoComponent,
  DashboardItemDirective,
  BuyingHomeComponent,
  SellAnotherHomeComponent,
  FaqComponent,
  SuccessDialogComponent,
  HomeDetailsEditDialogComponent,
  HomeDetailsStatsComponent,
  HomeDetailsAdditionalInfoComponent,
  HomeDetailsConditionComponent,
  HomeDetailsConditionDialogComponent,
  AddBuyingHomeDialogComponent,
  FullViewGalleryComponent,
  FaqAgentComponent,
  MediaIdsFullViewGalleryComponent,
  AgentContactAddAddressComponent,
  ManageAddressComponent,
  ManageAddressDialogComponent,
  CalculateEstimateDialogComponent,
  HomeValueWithStepsComponent,
  RealEstateAgentComponent,
  CashOfferComponent,
  CashOfferListComponent,
  OffersDashboardWrapperComponent,
  OfferDetailsComponent,
  AddAddressComponent,
  PropertyIdToHomeEstimateDirective,
  ContactAgentComponent,
  DashboardEasyLinkComponent,
  ImproveHomeEstimateComponent,
  DashboardModuleDirective,
  DashboardModuleHostDirective,
  VideoModuleComponent,
  HtmlModuleComponent,
  CtaModuleComponent,
  LenderComponent,
  ThreeStepProcessComponent,
  ComparablesMapDummyComponent,
  HomeEquityComponent,
  ClaimAddressComponent,
  OwnAnotherHomeComponent,
  HomeValueTrendV2Component,
  HomeValueTrendCalculationV2Component,
  PopupLeadFormComponent
];

@NgModule({
  declarations,
  imports: [
    CommonModule,
    RouterModule,
    FelloUiUtilsModule,
    MatDialogModule,
    MatSelectModule,
    MatTabsModule,
    GoogleMapsModule,
    MatTooltipModule,
    NgxSkeletonLoaderModule,
    FormsModule,
    NgxSkeletonLoaderModule,
    MatSnackBarModule,
    MatMenuModule,
    NgxGalleryModule,
    FelloUiLibModule,
    NgxChartsModule,
    MatProgressBarModule,
    NgxSpinnerModule,
    SignatureModule,
    MatSliderModule,
    CompCardComponent,
    CompLabelComponent,
    MatAutocompleteModule,
    MultiAvmComponent,
    HomeValueGraphComponent,
    MatAutocompleteModule,
    DynamicTooltipDirective,
    HomeValueGraphComponent,
    AvmUpdatedByAgentStripComponent,
    HomeValueRangeGraphComponent,
    OfferAvailabilityComponent,
    PropertyExternalImgPipe
  ],
  exports: [...declarations, CompCardComponent, CompLabelComponent]
})
export class DashboardModule {}
