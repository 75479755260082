export enum BillingPeriodUnit {
  monthly = "month",
  yearly = "year"
}

export const BillingPeriodUnitLabel: Record<BillingPeriodUnit, string> = {
  [BillingPeriodUnit.monthly]: "Monthly",
  [BillingPeriodUnit.yearly]: "Yearly"
};
export const BillingPeriodUnitPayLabel: Record<BillingPeriodUnit, string> = {
  [BillingPeriodUnit.monthly]: "Monthly",
  [BillingPeriodUnit.yearly]: "Upfront"
};
export const BillingPeriodUnitSecondaryLabel: Record<BillingPeriodUnit, string> = {
  [BillingPeriodUnit.monthly]: "monthly",
  [BillingPeriodUnit.yearly]: "annually"
};
export const BillingPeriodUnitShortLabel: Record<BillingPeriodUnit, string> = {
  [BillingPeriodUnit.monthly]: "mo",
  [BillingPeriodUnit.yearly]: "yr"
};

export interface PaymentPlanPricing {
  id: string;

  billingPeriod: number;
  billingPeriodUnit: BillingPeriodUnit;

  price: number;
  sortOrder: number;
  promotionalText?: string;
}

export interface PaymentPlan {
  id: string;

  name: string;

  description?: string;
  detailedDescription?: string;

  pricing: PaymentPlanPricing[];
  sortOrder: number;
}

export interface PaymentPlanEntitlement {
  featureName: string;

  entitlementName: string;

  value: string;
}
