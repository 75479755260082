<app-dashboard-header></app-dashboard-header>
<div class="container offer-page" #scrollingContainer>
  <!-- Left Panel -->
  <div class="panel left">
    <p class="text-size-14 fw-500 flex-box-center mb-3 c-pointer" [routerLink]="['../']">
      <lib-icon color="var(--color-primary)" class="mr-1" fontSize="14">left-big</lib-icon>
      Home Value Dashboard
    </p>
    <div class="cards-index">
      <ng-container *ngFor="let item of listItems">
        <a [ngClass]="{active: item.active}" *ngIf="item.isVisible" (click)="goToItem(item)"> {{ item.titleText }} </a>
      </ng-container>
    </div>
  </div>

  <!-- Center Section  -->
  <div class="cards-wrapper">
    <lib-manage-address></lib-manage-address>
    <lib-cash-offer-list appDashboardItem titleText="Offers"></lib-cash-offer-list>
    <app-home-details appDashboardItem titleText="Home Details" [moduleSetting]="moduleSettings"></app-home-details>
  </div>

  <!-- Right Panel -->
  <div class="panel right">
    <div *ngIf="agentSignature$ | async let agentSignature">
      <p class="text-size-20 fw-700 mb-3">Need help? Contact {{agentSignature.type === AgentSignatureType.AGENT ?'me':'Us'}}</p>
      <div class="db-card contact m-0">
        <div class="flex-box-center-between mb-2">
          <p class="fw-700" *ngIf="agentSignature.type === AgentSignatureType.AGENT">Real Estate Agent</p>
        </div>
        <lib-agent-signature [agentSignature]="agentSignature"></lib-agent-signature>
        <a class="button button-mid button-white button-primary mt-mobile-16 mt-3" target="_blank" (click)="openContactAgent()"
          >Contact {{ agentSignature.type === AgentSignatureType.AGENT ? 'Agent' : ''}}</a
        >
      </div>
    </div>
  </div>
</div>
