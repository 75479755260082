import {ComponentRef, Directive, Input, OnChanges, SimpleChanges, Type, ViewContainerRef} from "@angular/core";
import {AvailableDashboardModules, DashboardModuleSetting, DashboardModuleType} from "fello-model";
import {DashboardModuleDirective} from "./dashboard-module.directive";
import {DashboardItemDirective} from "./dashboard-item.directive";
import {CashOfferComponent} from "../components/cash-offer/cash-offer.component";
import {HomeValueWithStepsComponent} from "../components/home-value-with-steps/home-value-with-steps.component";
import {RealEstateAgentComponent} from "../components/real-estate-agent/real-estate-agent.component";
import {ComparablesComponent} from "../components/comparables/comparables.component";
import {HomeDetailsComponent} from "../components/home-details/home-details.component";
import {BuyingHomeComponent} from "../components/buying-home/buying-home.component";
import {VideoModuleComponent} from "../components/video-module/video-module.component";
import {HtmlModuleComponent} from "../components/html-module/html-module.component";
import {CtaModuleComponent} from "../components/cta-module/cta-module.component";
import {LenderComponent} from "../components/lender/lender.component";
import {ThreeStepProcessComponent} from "../components/three-step-process/three-step-process.component";
import {HomeEquityComponent} from "../components/home-equity/home-equity.component";
import {HomeValueTrendV2Component} from "../components/home-value-trend-v2/home-value-trend-v2.component";
import {MultiAvmComponent} from "../components/multi-avm/multi-avm.component";
import {SellingOptionsComponent} from "../components/selling-options/selling-options.component";

const ModuleTypeToComponentMapping: {
  [P in AvailableDashboardModules]: Type<DashboardModuleDirective<P>>;
} = {
  [DashboardModuleType.PRIMARY_CTA]: CashOfferComponent,
  [DashboardModuleType.HOME_ESTIMATE]: HomeValueWithStepsComponent,
  [DashboardModuleType.CONTACT_AGENT]: RealEstateAgentComponent,
  [DashboardModuleType.HOME_VALUE_TREND]: HomeValueTrendV2Component,
  [DashboardModuleType.COMPS]: ComparablesComponent,
  [DashboardModuleType.HOME_DETAILS]: HomeDetailsComponent,
  [DashboardModuleType.BUYING_PREF]: BuyingHomeComponent,
  [DashboardModuleType.VIDEO]: VideoModuleComponent,
  [DashboardModuleType.HTML]: HtmlModuleComponent,
  [DashboardModuleType.CTA]: CtaModuleComponent,
  [DashboardModuleType.LENDER]: LenderComponent,
  [DashboardModuleType.THREE_STEP_PROCESS]: ThreeStepProcessComponent,
  [DashboardModuleType.HOME_EQUITY]: HomeEquityComponent,
  [DashboardModuleType.MULTI_AVM]: MultiAvmComponent,
  [DashboardModuleType.SELLING_OPTIONS]: SellingOptionsComponent
};

@Directive({
  selector: "[libDashboardModuleHost]"
})
export class DashboardModuleHostDirective<ModuleType extends AvailableDashboardModules> implements OnChanges {
  @Input() moduleType: ModuleType;
  @Input() moduleSettings: DashboardModuleSetting<ModuleType>;
  @Input() moduleId: string;

  private _componentRef?: ComponentRef<DashboardModuleDirective<ModuleType>>;

  get dashboardItem(): DashboardItemDirective | undefined {
    return this._componentRef?.instance;
  }

  constructor(public viewContainerRef: ViewContainerRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.moduleType?.firstChange ||
      changes.moduleType?.previousValue !== changes.moduleType?.currentValue ||
      changes.moduleSettings?.firstChange ||
      changes.moduleSettings?.previousValue !== changes.moduleSettings?.currentValue ||
      changes.moduleId?.firstChange ||
      changes.moduleId?.previousValue !== changes.moduleId?.currentValue
    ) {
      this.renderModule();
    }
  }

  private renderModule(): void {
    const moduleType = this.moduleType;
    const moduleSetting = this.moduleSettings;
    const moduleToRender = ModuleTypeToComponentMapping[moduleType];
    if (moduleToRender) {
      if (this._componentRef?.instance.constructor !== moduleToRender) {
        this.viewContainerRef.clear();
        this._componentRef = this.viewContainerRef.createComponent<DashboardModuleDirective<ModuleType>>(moduleToRender);
      }
      this._componentRef.setInput("moduleSetting", moduleSetting);
      this._componentRef.setInput("moduleId", this.moduleId);
    } else {
      this.viewContainerRef.clear();
    }
  }
}
