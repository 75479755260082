<div class="db-card page-break-inside-avoid" *ngIf="totalHistories">
  <div>
    <lib-home-value-graph
      #homeValueGraph
      [dataProviders]="[DataProviderType.QUANTARIUM, DataProviderType.CL_BULK, DataProviderType.ATTOM_BULK]"
      [showCTAInDialog]="moduleSetting.showCTA"
    ></lib-home-value-graph>
  </div>
  <button
    class="link text-center w-100 fw-400 text-size-14 fw-500 mt-4 print-d-none"
    (click)="getHomeValueInfo()"
    *ngIf="moduleSetting.showCTA"
  >
    How do we calculate your home value estimate
  </button>
</div>
