import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {FqlFiltersEditDialogComponent} from "./fql-filters-edit-dialog/fql-filters.edit-dialog";
import {FqlFormFiltersComponent} from "./fql-form-filters/fql-form-filters.component";

@NgModule({
  imports: [FqlFiltersEditDialogComponent, FqlFormFiltersComponent],
  exports: [FqlFiltersEditDialogComponent, FqlFormFiltersComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FqlFiltersV2Module {}
