import {AfterViewInit, ChangeDetectorRef, Component, Input, TemplateRef, ViewChild} from "@angular/core";
import {AgentSignature, AgentSignatureElement, AgentSignatureType, AvailableSignatureElementTypes} from "fello-model";
import {AgentBrandingUtils} from "../../../../lib";

@Component({
  selector: "lib-agent-signature",
  templateUrl: "./agent-signature.component.html",
  styleUrls: ["./agent-signature.component.scss"]
})
export class AgentSignatureComponent implements AfterViewInit {
  @ViewChild("displayNameTemplate") displayNameTemplate: TemplateRef<any>;
  @ViewChild("brandNameTemplate", {static: false}) brandNameTemplate: TemplateRef<any>;
  @ViewChild("brokerageTemplate", {static: false}) brokerageTemplate: TemplateRef<any>;
  @ViewChild("licenseTemplate", {static: false}) licenseTemplate: TemplateRef<any>;
  @ViewChild("additonalSignatureTextTemplate", {static: false}) additonalSignatureTextTemplate: TemplateRef<any>;
  @ViewChild("emailIdTemplate", {static: false}) emailIdTemplate: TemplateRef<any>;
  @ViewChild("phoneTemplate", {static: false}) phoneTemplate: TemplateRef<any>;
  @ViewChild("officePhoneTemplate", {static: false}) officePhoneTemplate: TemplateRef<any>;
  @ViewChild("websiteTemplate", {static: false}) websiteTemplate: TemplateRef<any>;
  @ViewChild("addressTemplate", {static: false}) addressTemplate: TemplateRef<any>;

  @Input() agentSignature: AgentSignature;

  AgentSignatureType = AgentSignatureType;
  AgentBrandingUtils = AgentBrandingUtils;

  templateMap: Map<AvailableSignatureElementTypes, TemplateRef<unknown>> = new Map();

  constructor(private cdRef: ChangeDetectorRef) {}
  ngAfterViewInit() {
    this.templateMap.set(AgentSignatureElement.DISPLAY_NAME, this.displayNameTemplate);
    this.templateMap.set(AgentSignatureElement.BRAND_NAME, this.brandNameTemplate);
    this.templateMap.set(AgentSignatureElement.BROKERAGE, this.brokerageTemplate);
    this.templateMap.set(AgentSignatureElement.LICENSE, this.licenseTemplate);
    this.templateMap.set(AgentSignatureElement.CUSTOM_SIGNATURE_TEXT, this.additonalSignatureTextTemplate);
    this.templateMap.set(AgentSignatureElement.EMAIL_ID, this.emailIdTemplate);
    this.templateMap.set(AgentSignatureElement.PHONE, this.phoneTemplate);
    this.templateMap.set(AgentSignatureElement.OFFICE_PHONE, this.officePhoneTemplate);
    this.templateMap.set(AgentSignatureElement.WEBSITE, this.websiteTemplate);
    this.templateMap.set(AgentSignatureElement.ADDRESS, this.addressTemplate);

    this.cdRef.detectChanges();
  }
}
