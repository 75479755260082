<div class="flex-box-center-between mb-3">
  <h2 mat-dialog-title class="m-0 text-size-18 fw-700">{{ title }}</h2>
  <button class="button-icon button-primary-dark ml-4" mat-dialog-close *ngIf="!cancelButtonText">
    <app-icon fontSize="24">close</app-icon>
  </button>
</div>
<p class="mt-2 text-size-16 mb-3" *ngIf="description" [innerHTML]="description"></p>
<div class="form-field mb-4">
  <label for="form-first-name">{{ data.fieldLabel }}</label>
  <input
    [placeholder]="data.placeholder ?? ''"
    name="text"
    type="text"
    id="form-first-name"
    #textField="ngModel"
    [minlength]="data.minLength ?? null"
    [maxlength]="data.maxLength ?? null"
    [required]="data.textRequired ?? false"
    [ngModel]="data.text"
  />
</div>

<div class="flex-box-center-end flex-box-gap-3 mt-4" *ngIf="okButtonText || cancelButtonText">
  <button mat-dialog-close class="button-white" *ngIf="cancelButtonText">{{ cancelButtonText }}</button>
  <button
    [ngClass]="okButtonClasses ?? []"
    [mat-dialog-close]="{text: textField.value}"
    [disabled]="textField.invalid"
    *ngIf="okButtonText"
  >
    {{ okButtonText }}
  </button>
</div>
