import {NumberOrUnlimited, UNLIMITED} from "fello-model";

export class NumberUtils {
  /**
   * Checks if a value is "Unlimited".
   * @param value The value to check.
   * @returns True if the value is "Unlimited", false otherwise.
   */
  static isUnlimited(value: NumberOrUnlimited): value is typeof UNLIMITED {
    return value === UNLIMITED;
  }

  /**
   * Checks if a value is a number.
   * @param value The value to check.
   * @returns True if the value is a number, false otherwise.
   */
  static isNumber(value: NumberOrUnlimited): value is number {
    return typeof value === "number";
  }

  /**
   * Compares two NumberOrUnlimited values.
   * - If both are numbers, returns the result of comparing the numbers.
   * - If one is "Unlimited" and the other is a number, considers "Unlimited" as larger.
   * - If both are "Unlimited", returns 0 (considered equal).
   * @param a The first value to compare.
   * @param b The second value to compare.
   * @returns -1 if a < b, 1 if a > b, 0 if they are considered equal.
   */
  static compare(a: NumberOrUnlimited, b: NumberOrUnlimited): number {
    if (NumberUtils.isNumber(a) && NumberUtils.isNumber(b)) {
      return a - b;
    }
    if (NumberUtils.isUnlimited(a) && NumberUtils.isUnlimited(b)) {
      return 0;
    }
    if (NumberUtils.isUnlimited(a)) {
      return 1;
    }
    if (NumberUtils.isUnlimited(b)) {
      return -1;
    }
    return 0;
  }

  /**
   * Checks if the first NumberOrUnlimited value is greater than the second.
   * - Considers "Unlimited" as greater than any number.
   * - Returns false if values are equal.
   * @param a The first value to compare.
   * @param b The second value to compare.
   * @returns True if a > b, false otherwise.
   */
  static isGreater(a: NumberOrUnlimited, b: NumberOrUnlimited): boolean {
    return NumberUtils.compare(a, b) > 0;
  }

  /**
   * Checks if the first NumberOrUnlimited value is less than the second.
   * @param a The first value to compare.
   * @param b The second value to compare.
   * @returns True if a < b, false otherwise.
   */
  static isLessThan(a: NumberOrUnlimited, b: NumberOrUnlimited): boolean {
    return NumberUtils.compare(a, b) < 0;
  }

  /**
   * Checks if the first NumberOrUnlimited value is less than or equal to the second.
   * @param a The first value to compare.
   * @param b The second value to compare.
   * @returns True if a <= b, false otherwise.
   */
  static isLessThanOrEqual(a: NumberOrUnlimited, b: NumberOrUnlimited): boolean {
    return NumberUtils.compare(a, b) <= 0;
  }

  /**
   * Checks if the first NumberOrUnlimited value is greater than or equal to the second.
   * @param a The first value to compare.
   * @param b The second value to compare.
   * @returns True if a >= b, false otherwise.
   */
  static isGreaterThanOrEqual(a: NumberOrUnlimited, b: NumberOrUnlimited): boolean {
    return NumberUtils.compare(a, b) >= 0;
  }

  public static sanitizePhone(input: string): string {
    const digits = input.match(/(\d+)/g)?.join("");
    if (!digits) {
      return "";
    }
    return NumberUtils.formatPhone(digits.slice(Math.max(0, digits.length - 10), digits.length));
  }

  public static acreToSqft(acre: number): number {
    return acre * 43560;
  }

  public static formatPhone(inputPhn: string | undefined): string {
    if (!inputPhn) {
      return "";
    }
    let newVal = inputPhn.replace(/\D/g, "");
    if (newVal.length === 0) {
      newVal = "";
    } else if (newVal.length <= 3) {
      if (inputPhn === `(${newVal}`) {
        newVal = newVal.replace(/^(\d{0,3})\d/, "($1)");
      } else {
        newVal = newVal.replace(/^(\d{0,3})/, "($1)");
      }
    } else if (newVal.length <= 6) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, "($1) $2");
    } else if (newVal.length <= 10) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, "($1) $2-$3");
    } else {
      newVal = newVal.slice(-10); // pick last 10 chars of input when there are more than 10 digits
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, "($1) $2-$3");
    }
    return newVal;
  }

  public static increaseByXPerc(n: number, perc: number): number {
    return n * (1 + perc / 100);
  }

  public static round(value: number, toNearest = 1, maxDecimalPlaces = 0): number {
    return Number((Math.round(value / toNearest) * toNearest).toFixed(maxDecimalPlaces));
  }

  public static roundDown(value: number, toNearest = 1): number {
    return Math.floor(value / toNearest) * toNearest;
  }

  public static roundUp(value: number, toNearest = 1): number {
    return Math.ceil(value / toNearest) * toNearest;
  }

  public static percentage(value1: number, value2: number): number {
    if (!value1 || !value2) {
      return 0;
    }
    return (value1 / value2) * 100;
  }
}
