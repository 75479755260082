import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {
  AgencyContactSegment,
  AgencyContactSegmentCreateRequest,
  AgencyContactSegmentUpdateRequest,
  AgentSegmentPreference,
  ContactSegmentType
} from "fello-model";
import {AGENT_API_SERVICE_BASE_URL} from "./agent-api.service";

@Injectable({providedIn: "root"})
export class SegmentApiService {
  private baseUrl: string;

  constructor(private http: HttpClient, @Inject(AGENT_API_SERVICE_BASE_URL) private agentBaseUrl: string) {
    this.baseUrl = `${this.agentBaseUrl}/agent/segment`;
  }

  listAllSegments(): Observable<AgencyContactSegment[]> {
    return this.http.get<AgencyContactSegment[]>(`${this.baseUrl}/`);
  }

  createSegment(agencyContactSegment: AgencyContactSegmentCreateRequest): Observable<AgencyContactSegment> {
    return this.http.post<AgencyContactSegment>(`${this.baseUrl}/`, {agencyContactSegment});
  }

  updateSegment(segmentId: string, agencyContactSegment: AgencyContactSegmentUpdateRequest): Observable<unknown> {
    return this.http.patch(`${this.baseUrl}/${segmentId}`, {agencyContactSegment});
  }

  deleteSegment(segmentId: string): Observable<unknown> {
    return this.http.delete(`${this.baseUrl}/${segmentId}`);
  }

  // Agent Segment Preference related operations:
  getAgentSegmentPreferences(): Observable<AgentSegmentPreference> {
    return this.http.get<AgentSegmentPreference>(`${this.baseUrl}/preference`);
  }

  watchSegment(segmentId: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/${segmentId}/watch`, {});
  }

  unwatchSegment(segmentId: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/${segmentId}/unwatch`, {});
  }

  updateVisiblePrivateSegmentIds(segmentIds: string[]): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/visible-segments/private`, {segmentIds});
  }

  updatePinnedSegmentIds(segmentIds: string[]): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/visible-segments/pinned`, {segmentIds});
  }

  updateVisibleSharedSegmentIds(segmentIds: string[]): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/visible-segments/shared`, {segmentIds});
  }

  updateVisibleStandardSegmentIds(segmentIds: string[]): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/visible-segments/standard`, {segmentIds});
  }
  updateSegmentsGroupOrder(newOrder: ContactSegmentType[]): Observable<unknown> {
    // return of(null);
    return this.http.put<void>(`${this.baseUrl}/visible-segments/group-reorder`, {newOrder});
  }
}
