import {ComponentRef, Directive, ElementRef, HostListener, Input, OnDestroy, TemplateRef} from "@angular/core";
import {ConnectedPosition, Overlay, OverlayPositionBuilder, OverlayRef} from "@angular/cdk/overlay";
import {ComponentPortal} from "@angular/cdk/portal";
import {CustomTooltipComponent} from "../../fello-ui-lib/components/custom-tooltip/custom-tooltip.component";
import {Subscription} from "rxjs";

export type TooltipPosition = "above" | "below" | "left" | "right" | "right-above" | "right-below" | "left-below";

const TOOLTIP_CLOSE_DELAY = 100;

@Directive({
  selector: "[libTooltip]",
  standalone: true
})
export class TooltipDirective implements OnDestroy {
  /**
   * This will be used to show tooltip or not
   * This can be used to show the tooltip conditionally
   */
  @Input() showToolTip = true;

  @Input(`libTooltip`) content?: string | TemplateRef<any> | null;

  @Input() tooltipClass: string;

  @Input() tooltipPosition: TooltipPosition = "below";

  @Input() tooltipContext?: any;

  private overlayRef: OverlayRef | null;

  /**
   * Boolean value to keep track if the mouse is over the tooltip element.
   * This ensures that the tooltip is not closed when the user is interacting (selecting the text or just hovering over the tooltip) with the tooltip.
   */
  private mouseOverToolTip = false;

  /**
   * Variable to keep the reference of the timeout used for delaying the closing of the tooltip element.
   */
  private hideTimeout: ReturnType<typeof setTimeout> | null = null;

  private tooltipMouseEventsSubscriptions?: Subscription;

  constructor(private overlay: Overlay, private overlayPositionBuilder: OverlayPositionBuilder, private elementRef: ElementRef) {}

  getConnectedPosition(): ConnectedPosition {
    switch (this.tooltipPosition) {
      case "below":
        return {
          originX: "center",
          originY: "bottom",
          overlayX: "center",
          overlayY: "top",
          offsetY: 10
        };
      case "above":
        return {
          originX: "center",
          originY: "top",
          overlayX: "center",
          overlayY: "bottom",
          offsetY: -10
        };

      case "left":
        return {
          originX: "start",
          originY: "center",
          overlayX: "end",
          overlayY: "center",
          offsetX: -10
        };

      case "right":
        return {
          originX: "end",
          originY: "center",
          overlayX: "start",
          overlayY: "center",
          offsetX: 10
        };

      case "right-above":
        return {
          originX: "end",
          originY: "top",
          overlayX: "end",
          overlayY: "bottom",
          offsetY: -10
        };

      case "right-below":
        return {
          originX: "end",
          originY: "bottom",
          overlayX: "end",
          overlayY: "top",
          offsetY: 10
        };

      case "left-below":
        return {
          originX: "start",
          originY: "bottom",
          overlayX: "start",
          overlayY: "top",
          offsetY: 10
        };
    }
  }

  /**
   * This method will be called whenever mouse enters in the Host element
   * i.e. where this directive is applied
   * This method will show the tooltip by instantiating the McToolTipComponent and attaching to the overlay
   */
  @HostListener("mouseenter")
  show(): void {
    // Returning if the previous instance of tooltip is still not closed.
    if (this.overlayRef) {
      return;
    }

    if (this.showToolTip && this.content) {
      const positionStrategy = this.overlayPositionBuilder
        .flexibleConnectedTo(this.elementRef)
        .withPositions([this.getConnectedPosition()]);

      this.overlayRef = this.overlay.create({positionStrategy});
    }
    // attach the component if it has not already attached to the overlay
    if (this.overlayRef && !this.overlayRef.hasAttached() && this.content) {
      const tooltipRef: ComponentRef<CustomTooltipComponent> = this.overlayRef.attach(new ComponentPortal(CustomTooltipComponent));
      if (this.content instanceof TemplateRef) {
        tooltipRef.instance.contentTemplate = this.content;
        tooltipRef.instance.tooltipContext = this.tooltipContext;
      } else {
        tooltipRef.instance.text = this.content;
      }
      tooltipRef.instance.tooltipClass = this.tooltipClass;
      tooltipRef.instance.tooltipPosition = this.tooltipPosition;

      // Subscribing to tooltipRef mouse events
      this.tooltipMouseEventsSubscriptions = tooltipRef.instance.mouseEnterAndLeave$.subscribe(event => {
        switch (event) {
          case "mouseenter": {
            this.mouseOverToolTip = true;
            break;
          }
          case "mouseleave": {
            this.mouseOverToolTip = false;
            this.closeToolTip();
            break;
          }
        }
      });
    }
  }

  /**
   * This method will be called when mouse goes out of the host element
   * i.e. where this directive is applied
   * This method will close the tooltip by detaching the overlay from the view
   */
  @HostListener("mouseleave")
  hide(): void {
    // Hide with a delay to allow for moving the cursor to the tooltip
    this.hideTimeout = setTimeout(() => {
      // Only when the mouse is not over the tooltip element we close the tooltip.
      if (!this.mouseOverToolTip) {
        this.closeToolTip();
      }
    }, TOOLTIP_CLOSE_DELAY);
  }

  /**
   * Destroy lifecycle event handler
   * This method will make sure to close the tooltip
   * It will be needed in case when app is navigating to different page
   * and user is still seeing the tooltip; In that case we do not want to hang around the
   * tooltip after the page [on which tooltip visible] is destroyed
   */
  ngOnDestroy(): void {
    this.closeToolTip();
  }

  /**
   * This method will close the tooltip by detaching the component from the overlay
   */
  private closeToolTip(): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef.dispose();
      this.overlayRef = null;
    }

    // Clearing the timeout and it's value as null on close.
    if (this.hideTimeout) {
      clearTimeout(this.hideTimeout);
      this.hideTimeout = null;
    }

    if (this.tooltipMouseEventsSubscriptions) {
      this.tooltipMouseEventsSubscriptions.unsubscribe();
    }
  }
}
